import React from 'react'

import { RenderModules } from 'src/utils/renderModules'
import { SEO } from 'src/components/SEO'

export interface PostProps {
  pageContext: {
    main: {
      modules: []
      slug: {
        current: string
      }
      title: string
    }
    meta: {}
  }
  path?: string
  preview?: boolean
}

const Post = ({ path, pageContext, preview = false }: PostProps) => {
  const {
    main: { modules, slug },
    meta,
  } = pageContext

  const url = slug.current === 'home' ? '' : path
  return (
    <div className="post-page">
      {preview && <div className="bcblue ac cw x p1">This is a Preview</div>}
      <SEO metaInfo={meta} pagePath={url} />
      <div className="header-room">{RenderModules(modules)}</div>
    </div>
  )
}

export default Post
