import React, { useState, useEffect, useCallback } from 'react'
import { Router } from '@reach/router'

import { useLoads } from 'react-loads'

import Page from 'src/templates/page'
import Team from 'src/templates/team'
import CaseStudyPage from 'src/templates/caseStudyPage'
import Post from 'src/templates/post'

// import Product from "src/templates/product"

import sanityClient from '@sanity/client'

import { pageQuery, teamQuery, caseStudyQuery } from 'src/utils/queries'

const client = sanityClient({
  apiVersion: '2021-03-25',
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  useCdn: false,
  token:
    'skkev7sIWq0ml61fdMwRPWJ1eQsILkLuJKi6FxpF4EnBwuPbWUGHh2hJdQw9quTDPjkPMnGIbauoRd7VrW82MgJhB5s8eCjaDWIv54TKeVe6K4RKkTtcj8BbkHsMtIwhUVbK5wqsk50GSBkTkznvxDPkcuXbw50BzlF1k2Q98VBf2GGmjqLY',
})

const PreviewPage = ({ document }: { document: string }) => {
  const [doc, setDoc] = useState(null as any)

  //  @ts-ignore
  const queryDraft = `*[_id == "${document}"]  {
    ...,
    content {
      ...,
      main {
        ...,
        modules [] {
          ...,
          _type == "imageFullWidth" => {
            image {
              ...,
          asset-> {...}


            }
          },

        }

      }
    }

  }`

  // @ts-ignore
  const queryPreviewPage = `*[_id == "${document}"]  {
    ...,
    content {
      ...,
      main {
        ...,
        modules [] {
          ...,
          relatedMediaArray [] -> {
            ...
          },


          image{asset->{...}},

          _type == "textVisualBlockMapSlider"  => {
            textVisualBlockImageDescriptionText {
              ...,
              linkToPage -> {...},
              image{asset ->{...}}


            }
          },

          _type == "locationClusterByProduct" => {
            filterReference -> {...},
            mapLocationGeopoints[] {
              _type == 'locationPoint' => {
                insight ->{...},
                locationPointReference->{...,geopoint->{...}},
              },
              _type == 'clusterPoint' => {
                locationClusterPointReference->{...},
                insightsDropDownCluster[] -> {...}
              }
            }
          },

          _type == "infrastructureDrawer"=> {
            headerPhoto {asset ->{...}},
            logo {asset ->{...}},
            relatedCaseStudies [] ->{...},
            sectorOfCompany->{...},

          },
          _type == "mediaTypeContainer" => {
            references [] {
              ...,
              featuredBlockImage {asset -> {...}},
              featuredExternalArticleReference -> {...},
              externalArticles [] -> {...},
              insightBlocks {
                externalArticles [] -> {...}
              }
            }
          }



        }
      }
    }

  }`
  // @ts-ignore
  const queryCaseStudyPage = `*[_id == "${document}"]  {
    ...,

  content {
  ...,
  main {
  ...,
  modules {
  ...,
  locationCategory -> {...},
  image {asset->{...}},
  mainImage{asset->{...}},
  portfolioCategory->{...},
  funds -> {...}

}
}
}
  }`

  const queryTeamPreviewPage = `*[_id == "${document}" ]  {
    ...,
    image {
      asset -> {...}
    },
    teamMembersRoleRef -> {...},
    teamMembersLocation -> {...}


  }`
  const handlePreviewFetch = useCallback(
    () =>
      client.fetch(queryDraft).then((response: any) => {
        switch (response[0]._type) {
          case 'page':
            client.fetch(queryPreviewPage).then(res => {
              setDoc(res[0])
            })
            break

          case 'teamMember':
            client.fetch(queryTeamPreviewPage).then(res => {
              setDoc(res[0])
            })
            break
          case 'caseStudy':
            client.fetch(queryCaseStudyPage).then(res => {
              setDoc(res[0])
            })
            break
          case 'post':
            client.fetch(queryDraft).then(res => {
              console.log(res)
              setDoc(res[0])
            })
            break
          default:
            break
        }
      }),
    []
  )

  const { error, isResolved, isPending, isReloading, load } = useLoads(
    'handlePreviewFetch',
    handlePreviewFetch as any,
    {
      defer: true,
    }
  )

  useEffect(() => {
    load()
  }, [0])

  const renderPreview = () => {
    if (doc) {
      console.log(doc)
      switch (doc._type) {
        case 'page':
          return <Page preview={true} location={{ pathname: false }} pageContext={doc.content} />
        case 'teamMember':
          return <Team preview={true} location={{ pathname: '/team' }} pageContext={doc} />

        case 'caseStudy':
          return (
            <CaseStudyPage
              preview={true}
              pageContext={doc.content}
              location={{ pathname: false }}
            />
          )
        case 'post':
          return (
            <Post
              preview={true}
              pageContext={doc.content}
              // location={{ pathname: false }}
            />
          )

        default:
          break
      }
    }
  }
  return (
    <>
      {(isPending || isReloading) && (
        <div className="ac">
          <span>Loading</span>
        </div>
      )}
      {isResolved && !isPending && renderPreview()}
    </>
  )
}

const Previews = () => {
  return (
    <div>
      <Router>
        <PreviewPage path="/previews/:document" />
      </Router>
    </div>
  )
}

export default Previews
