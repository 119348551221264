import React from 'react'

import { RenderModules } from 'src/utils/renderModules'
import { SEO } from 'src/components/SEO'

import { RevealOnMount } from '../utils/revealOnMount'

export interface PageProps {
  pageContext: {
    main: {
      modules: []
      slug: {
        current: string
      }
      title: string
    }
    meta: {}
  }
  path?: string
  preview?: boolean
}

const Page = ({ path, location, pageContext, preview }: PageProps) => {
  const {
    main: { modules, slug },
    meta,
  } = pageContext

  const url = slug.current === 'home' ? '' : path

  return (
    <RevealOnMount location={location}>
      <SEO metaInfo={meta} pagePath={url} />
      <div className="header-room">{RenderModules(modules, url)}</div>
    </RevealOnMount>
  )
}

export default Page
