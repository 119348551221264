import React, { useState, useLayoutEffect, useEffect, useRef } from 'react'
import { useSetScrollPosition, useScrollData } from 'src/context/scrollProvider'
import cx from 'classnames'

interface RevealOnMountProps {
  location: any
  children: React.ReactNode
}

export const RevealOnMount = ({ location, children }: RevealOnMountProps) => {
  const [shouldFadeIn, setShouldFadeIn] = useState(false)
  const [isMounted, setIsMounted] = useState(false)
  const setScroll = useSetScrollPosition()
  const scrollData = useScrollData()
  const scrolRef = useRef('')

  useLayoutEffect(() => {
    if (location && location.action === 'PUSH') {
      setShouldFadeIn(true)
    }
    let isPreviousTeamPage
    let isPreviousCaseStudy

    try {
      isPreviousTeamPage =
        location.state.previous && location.state.previous.url.split('/').includes('team')
      isPreviousCaseStudy =
        location.state.previous && location.state.previous.url.split('/').includes('insight')
    } catch (err) {
      isPreviousTeamPage = false
      isPreviousCaseStudy = false
    }

    if (isPreviousTeamPage || isPreviousCaseStudy) {
      setTimeout(() => {
        window.scrollTo(0, scrollData)
      }, [100])
    }
  }, [])

  useEffect(() => {
    const trackScrollPosition = () => {
      if (
        location.pathname === '/our-team' ||
        location.pathname === '/portfolio-buyouts' ||
        location.pathname === '/portfolio-infrastructure'
      ) {
        const scrollY = window.scrollY //Don't get confused by what's scrolling - It's not the window
        const ref = scrolRef
        setScroll(scrollY)
      }
    }
    window.addEventListener('scroll', trackScrollPosition)
    return () => window.removeEventListener('scroll', trackScrollPosition)
  }, [location.pathname])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  return (
    <div className={cx(shouldFadeIn ? 'fade-in-on-mount' : '', isMounted ? 'is-mounted' : '')}>
      {children}
    </div>
  )
}
