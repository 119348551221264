import React, { useEffect, useState } from 'react'

import { Image } from 'src/components/image'
import imageUrlBuilder from '@sanity/image-url'

import { RevealOnMount } from 'src/utils/revealOnMount'

import { SEO } from 'src/components/SEO'

import { navigate } from 'gatsby'
import { XNavy } from 'src/components/svgs'

import { StandardText } from 'src/components/global/standardText'

import { PageLink } from 'src/components/link'
import Page from './page'

const doesPreviousLinkExist = (location: any) => {
  try {
    return location.state.previous.url
  } catch (err) {
    return false
  }
}

const LinkController = (type: string) => {
  switch (type) {
    case 'buyouts':
      return '/portfolio-buyouts'
    case 'infrastructure':
      return '/portfolio-infrastructure'
  }
}

const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
}

const builder = imageUrlBuilder(sanityConfig)

function urlFor(source) {
  return builder.image(source)
}

export interface PageProps {
  pageContext: {
    main: {
      modules: any
      slug: {
        current: string
      }
      title: string
    }
    meta: {}
  }
  location: any
  path?: string
  preview?: boolean
}

const CaseStudyPage = ({ path, location, pageContext, preview = false }: PageProps) => {
  const {
    main: { slug, title, platform, modules },
    meta,
  } = pageContext
  const [categoryDetails, setCategoryDetails] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const url = slug.current === 'home' ? '' : path
  const previousUrl = doesPreviousLinkExist(location) ? location.state.previous.url : '/'
  const {
    companyColor,
    mainImage,
    image,
    headerBlurb,
    sectorCategoryRef,
    statusCategory,
    caseStudyStats,
    contentOne,
    contentTwo,
    contentThree,
    exitColortList,
    svgMarkDown,
    locationCategory,
    website,
    portfolioCategory,
  } = pageContext.main.modules

  async function fetchCategoryDetails(bySlugId: string) {
    let json
    try {
      const response = await fetch(
        `/.netlify/functions/getCaseStudyCategories?slug=${bySlugId.current}`
      )
      switch (response.status) {
        case 200:
          json = await response.json()
          // eslint-disable-next-line no-var
          var { sectorCategoryRef, statusCategory } = json.response.content.main.modules
          setCategoryDetails({
            sectorCategoryRef,
            statusCategory,
          })
          setIsLoading(false)
          break
        default:
          setIsLoading(false)

          return null
      }
    } catch (err) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchCategoryDetails(slug)
  }, [])

  return (
    <RevealOnMount location={location}>
      <div style={{ paddingTop: preview ? '8rem' : '' }} className="case-study-page">
        {/* {preview && <div className="  bcblue ac cw x p1">This is a Preview</div>} */}
        <SEO metaInfo={meta} pagePath={url} />
        <div className={"case-study-container " + (contentThree ? 'thirdRow' : '')}>
          <div
            className="box padding-box __company-information"
            style={{ backgroundColor: companyColor }}
          >
            <div className="--bread-crumb ">
              <PageLink
                previousLink={path}
                activeClass="null"
                to={LinkController(portfolioCategory && portfolioCategory.slug.current)}
                className="white flex neue-pro-55-text-links "
              >
                {portfolioCategory && portfolioCategory.title}
              </PageLink>
              {platform ? (
                <div className="flex">
                  <p className="white flex neue-pro-55-text-links margin-right-1rem margin-left-1rem">
                    {'>'}
                  </p>
                  <PageLink
                    activeClass="null"
                    previousLink={path}
                    to={LinkController(portfolioCategory && portfolioCategory.slug.current)}
                    className="white flex neue-pro-55-text-links margin-right-1rem"
                  >
                    {' '}
                    {platform}
                  </PageLink>
                  <p className="white flex neue-pro-55-text-links margin-right-1rem">{'>'}</p>
                </div>
              ) : (
                <p className="flex neue-pro-55-text-links white margin-left-1rem margin-right-1rem">{` >`}</p>
              )}

              <p className="flex neue-pro-55-text-links white ">{title}</p>
            </div>
            <div className="--company-information-text ">
              <div className="__navigation-container">
                <div
                  className="--company-information-text__svg"
                  dangerouslySetInnerHTML={{ __html: svgMarkDown }}
                />

                <PageLink
                  className="cursor __mobile-x "
                  activeClass="null"
                  previousLink={path}
                  to={LinkController(portfolioCategory && portfolioCategory.slug.current)}
                >
                  <XNavy onClick={() => null} className="cursor __mobile-x " color={'white'} />
                </PageLink>
              </div>
              <StandardText
                className=" __mobile-header-blurb optima-subhead  white margin-top-2rem margin-bottom-2rem"
                data={{ text: headerBlurb }}
              />

              <div className="flex column __last-child-links">
                {website && (
                  <a
                    href={website}
                    target="_blank"
                    rel="noopener _custom noreferrer"
                    className="white neue-pro-55-text-links margin-bottom-2rem "
                  >
                    Visit Website{' '}
                  </a>
                )}

                {platform && (
                  <p className="white neue-pro-55-text-links margin-bottom-2rem">
                    {`Platform: ${platform}`}{' '}
                  </p>
                )}

                {locationCategory && (
                  <p className="white neue-pro-55-text-links  margin-bottom-2rem ">
                    {`Location: ${locationCategory.title} `}{' '}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${urlFor(image)})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
            className="box padding-box __image-background"
          >
            <PageLink
              previousLink={path}
              activeClass="null"
              className=""
              to={LinkController(portfolioCategory && portfolioCategory.slug.current)}
            >
              <XNavy
                onClick={() => null}
                className="cursor _desktop-exit"
                color={exitColortList && exitColortList.value}
              />
            </PageLink>
          </div>  

		  {contentThree && (
			  <div className="box padding-box full-width">
          	<StandardText className="blue neue-pro-55-text-links full-width " data={{ text: contentThree }} />
          	</div>
           )}

          <div className="box padding-box __points-information">
            <StandardText className="blue neue-pro-55-text-links " data={{ text: contentOne }} />
            <StandardText className="blue neue-pro-55-text-links " data={{ text: contentTwo }} />
          </div>

          <div className="box padding-box __stats">
            <StandardText className="optima-subhead __desktop " data={{ text: headerBlurb }} />
            <div className="--container ">
              {caseStudyStats &&
                caseStudyStats.map((item: any, index) => {
                  return <TitleBlock key={index} title={item.title} content={item.description} />
                })}
            </div>
          </div>
        </div>
      </div>
    </RevealOnMount>
  )
}

export default CaseStudyPage

const TitleBlock = ({ title, content, key }: any) => {
  return (
    <div key={key + '_title-block'} style={{ display: 'grid' }} className="title-block">
      <StandardText
        data={{ text: title }}
        className="neue-pro-55-text-links blue margin-bottom-1rem "
      />
      <StandardText
        data={{ text: content }}
        className="neue-pro-light-45-large-nums blue align-self-flex-end"
      />
    </div>
  )
}
